import dayjs from "dayjs"

const DEBUG_DATE = null // || "2024-05-10 10:20"
if (DEBUG_DATE) console.warn("DEBUG DATE", DEBUG_DATE)

class Util {

  /**
   * Encode the string using cyrb53 algo - not a real hash but it ensure enough uniqunes with shorter hashes for most uses
   * @param {string} str 
   * @param {integer} seed 
   * @returns string
   */
  static cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i)
      h1 = Math.imul(h1 ^ ch, 2654435761)
      h2 = Math.imul(h2 ^ ch, 1597334677)
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
    return "" + (4294967296 * (2097151 & h2) + (h1 >>> 0))
  }

  /**
   * Sorts array of JSON objects per given key and direction
   * @param {array} data 
   * @param {string} key 
   * @param {string} direction - [asc] | desc
   * @returns json
   */
  static sortJSON = (data, key, direction = "asc") => {
    const direction_factor = direction === "asc" ? 1 : -1
    return [...data].sort((a, b) => {
      if (typeof a[key] === "string") {
        return a[key].localeCompare(b[key]) * direction_factor
      } else {
        return (a[key] < b[key] ? -1 : 1) * direction_factor
      }
    })
  }

  /**
   * Sorts array of JSON objects per multiple keys and directions
   * @param {array} data 
   * @param {array} keys 
   * @returns 
   */
  static sortJSONByMultiKey = (data, keys, logSteps = false) => {
    return [...data].sort((a, b) => {
      if (logSteps) console.log("compare", a, b)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i].key
        const direction_factor = keys[i].direction === "asc" ? 1 : -1

        let res =
          (typeof a[key] === "string")
            ? a[key].localeCompare(b[key]) * direction_factor
            : (a[key] < b[key] ? -1 : 1) * direction_factor

        if (logSteps) console.log("key", keys[i].key, keys[i].direction, res ? res : "[skipping]")

        if (res) return res // important - matching values are ignored allowing next key to be evaluated
      }
      return 0 // ensure that matched values are finally resolved, otherwise sorting function is not complete
    })
  }

  /**
   * Removes duplicates of the given key in object
   * @param {json} data 
   * @param {string} key 
   * @returns json
   */
  static removeDuplicatesJSON = (data, key) => {
    var result = data.reduce((unique, o) => {
      if (!unique.some(obj => obj[key] === o[key])) {
        unique.push(o)
      }
      return unique
    }, [])
    return result
  }

  /**
   * Calculates and sets the top page padding (originally margin) by header height
   * @param {object} document 
   * @param {boolean} hideHeaderOnScroll 
   * @returns 
   */
  static fixPageMargin = (document, hideHeaderOnScroll) => {
    if (!document || typeof (window) === "undefined") return

    let headers = document.getElementsByClassName("page-header")
    let pages = document.getElementsByClassName("page")

    if (headers.length > 0 && pages.length > 0) {
      let pgStyle = window.getComputedStyle(pages[0])
      let hhStyle = window.getComputedStyle(headers[0])

      let pagePaddingTop = parseFloat((pgStyle.paddingTop + "").replace("px", ""))
      let headerHeight = parseFloat((hhStyle.height + "").replace("px", ""))

      let newPageHeight = (headerHeight + 0) + "px"

      if (pagePaddingTop !== newPageHeight) {
        pages[0].style.paddingTop = newPageHeight
      }
    }
  }

  /**
   * Ensures floating header top position after page is scrolled
   * @param {object} document 
   * @param {object} window 
   * @param {number} scrollPos 
   * @returns 
   */
  static handleHeaderScroll = (document, window, scrollPos) => { // not used currently
    let result = 0

    let headers = document.getElementsByClassName("page-header")
    if (headers.length > 0) {
      let hh = headers[0].offsetHeight
      let deltaY = window.scrollY - scrollPos
      if (window.scrollY > hh && deltaY > 0) {
        if (headers[0].style.top === "0px") {
          headers[0].style.top = `-${hh + 10}px` // hide
        }
      } else {
        headers[0].style.top = 0 // show
      }

      result = window.scrollY
    }

    return result
  }

  /**
   * Check if e-mail address is valid
   * @param {string} email 
   * @returns boolean
   */
  static isValidEMail = (email) => {
    let re = /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/gm
    return re.test(email)
  }

  /**
   * Check if PIN is valid
   * @param {string} email 
   * @returns boolean
   */
  static isValidPIN = (pin, expectedLength = 4) => {
    let re = new RegExp(`^\\d{${expectedLength},${expectedLength}}$`, "g")
    return re.test(pin)
  }

  /**
   * Check if phone is valid - NOT YET IMPLEMENTED
   * @param {string} phone 
   * @returns boolean
   */
  static isValidPhone = (phone) => {
    return true // TODO: add check and allow only 0-9, -, +, (, ), space
  }

  /**
   * Checks if given value is really empty (checking for all JS quirks)
   * @param {any} value 
   * @param {boolean} trimString 
   * @returns boolean
   */
  static isEmpty = (value, trimString = false) => {
    return (
      (value == null) ||
      (typeof value === "string" && (trimString ? (value.trim() === "") : (value === ""))) ||
      (value.size === 0) ||
      (value.length === 0 && typeof value !== "function") ||
      (value.constructor === Object && Object.keys(value).length === 0)
    )
  }

  /**
   * Joins the string array into single string
   * @param {array} data 
   * @returns string
   */
  static stringifyArray = (data, options = {}) => {
    let result = (data instanceof Array) ? data.join("") : data

    if (options.newLineToBreak) {
      result = result.replace(/\r\n/g, '<br/>')
    }

    return result
  }

  /**
   * Replaces all keys with values from params array by using Moustache annotation ({{var}})
   * @param {string} string 
   * @param {array} params 
   * @returns string
   */
  static replaceStringParams = (string, params) => {
    if (!params) return string

    let result = string

    const keys = Object.keys(params)
    keys.forEach(key => {
      result = result.replaceAll(`{{${key}}}`, params[key])
    })

    return result
  }

  /**
   * Joins array into single string by using delimiter
   * If clearNulls is active, all blanks in array will be removed before joining
   * @param {array} data 
   * @param {string} delimiter 
   * @param {boolean} clearNulls 
   * @returns string
   */
  static joinArray = (data, delimiter = "", clearNulls = true) => {
    if (data instanceof Array)
      return data.filter(i => clearNulls ? i : true).join(delimiter)
    else
      return data
  }

  /**
   * Pads the string with leading zeroes until length is equal to given size
   * @param {string} num 
   * @param {integer} size 
   * @returns string
   */
  static zeroPad = (num, size) => {
    let s = num + ""
    while (s.length < size) s = "0" + s
    return s
  }

  static getDebugDate = () => {
    return DEBUG_DATE
  }

  static now = (date = null) => {
    let res = dayjs()

    if (DEBUG_DATE) res = dayjs(DEBUG_DATE) // global
    if (date) res = dayjs(date) // local

    return res
  }

  /**
   * Calculates and prepares JSON object with values important for checks wheather date falls into start-end date limits
   * This is needed on several places - for example if survey is available, if event is active and so on
   * @param {json} limits 
   * @returns json
   */
  static calcDateLimits = (limits) => {
    const now = this.now()

    const { from, to } = limits
    const startDate = dayjs(from)
    const endDate = dayjs(to)

    // console.log("calcDateLimits", now, from, to)

    return {
      startOK: (from === null || now.isSame(startDate) || now.isAfter(startDate)),
      endOK: (to === null || now.isBefore(endDate)),
      date1: from !== null ? dayjs(startDate).format("L") : null,
      time1: from !== null ? dayjs(startDate).format("LT") : null,
      date1num: from !== null ? dayjs(startDate).format("LL") : null,
      date2: to !== null ? dayjs(endDate).format("L") : null,
      time2: to !== null ? dayjs(endDate).format("LT") : null,
      date2num: to !== null ? dayjs(endDate).format("LL") : null
    }
  }

  /**
   * Calculates event state regarding to date limits when event is being held
   * @param {string} startDateTime 
   * @param {string} endDateTime 
   * @returns json
   */
  static calcEventStatus = (startDateTime, endDateTime) => {
    const now = this.now()

    let result = {
      before: false,
      after: false,
      active: false
    }

    if (startDateTime && endDateTime) {
      const startOK = now.isSame(startDateTime) || now.isAfter(startDateTime)
      const endOK = now.isSame(endDateTime) || now.isBefore(endDateTime)
      result = {
        before: now.isBefore(startDateTime),
        after: now.isAfter(endDateTime),
        active: startOK && endOK
      }
    }

    return result
  }

  /**
   * Simple check if "window" is available (we are running in browser)
   * @returns boolean
   */
  static isBrowser = () => {
    return typeof window !== "undefined"
  }

  /**
   * Checks if "localStorage" is available
   * @returns boolean
   */
  static hasLocalStorage = () => {
    try {
      return (typeof localStorage === "object" && navigator.cookieEnabled)
    } catch (e) {
      return false
    }
  }

  /**
   * Checked if light mode is activated via localStorage value or preferred user style (browser)
   * @returns boolean
   */
  static isLightMode = () => {
    if (this.isBrowser() && this.hasLocalStorage()) {
      const wlm = localStorage.getItem("weblica-light-mode")
      if (typeof wlm === "string") {
        return wlm === "true"
      } else {
        const mql = window.matchMedia("(prefers-color-scheme: dark)")
        if (typeof mql.matches === "boolean") {
          return !mql.matches
        }
      }
    }

    return false // default
  }

  /**
   * Fetches a single entry, identified by given ident, from array of images returned by staticQuery (GraphQL)
   * @param {array} images 
   * @param {string} ident 
   * @returns object
   */
  static getImageByIdent = (images, ident) => {
    const found = images.allFile.edges.filter(item => {
      // console.log(ident, item.node.name, item.node.relativePath)
      return item.node.name === ident || item.node.relativePath.indexOf(ident) !== -1
    })

    // console.log("getImageByIdent", ident, found)

    // return single image
    if (found.length > 0 && found[0].node.childrenImageSharp.length > 0)
      return found[0].node.childrenImageSharp[0].gatsbyImageData
    else
      return null
  }

  /**
   * Fetches multiple entries, identified by path, from array of images returned by staticQuery (GraphQL)
   * @param {array} images 
   * @param {string} path 
   * @param {string} ignoredPath 
   * @returns object
   */
  static getMultipleImagesByRelativePath = (images, path, ignoredPath) => {
    const found = images.allFile.edges.filter(item => {
      return item.node.relativePath.indexOf(path) !== -1 && item.node.relativePath.indexOf(ignoredPath) === -1
    })
    if (found.length > 0) {
      const result = found.map(f => {
        return (f.node.childrenImageSharp.length > 0) ? f.node.childrenImageSharp[0].gatsbyImageData : null
      })
      return result
    } else
      return null
  }

  /**
   * Orders events by using "order" field (manual option in config.js)
   * @param {json} events 
   * @returns 
   */
  static getOrderedEventsArray = (events) => {
    let eventArray = []
    Object.keys(events).forEach(e => {
      eventArray.push({ "ident": events[e].ident, "order": events[e].order })
    })
    let sortedArray = Util.sortJSON(eventArray, "order")

    let eventKeys = []
    sortedArray.forEach(e => {
      eventKeys.push(e.ident)
    })

    return eventKeys
  }

  /**
   * Fetches item from array by given index but with doing some logical and data limit checks
   * @param {array} data 
   * @param {integer} defaultIndex 
   * @returns object
   */
  static getObjectOrArrayItem = (data, defaultIndex = 0) => {
    if (data instanceof Array) {
      if (data.length > defaultIndex) {
        return data[defaultIndex]
      } else if (data.length > 0) {
        return data[0]
      } else {
        return null
      }
    } else {
      return data
    }
  }

  /**
   * Fetches correct language entry in "text" object
   * If there is no entry for given language, default language (English) is used
   * If default language is also not available, first array entry is returned
   * @param {json} text 
   * @param {string} lang 
   * @returns string
   */
  static tx = (text, lang) => {
    const defaultLng = "en"
    if (text instanceof Object)
      if (text.hasOwnProperty(lang))
        return text[lang]
      else if (text.hasOwnProperty(defaultLng))
        return text[defaultLng]
      else
        return text[Object.keys(text)[0]]
    else
      return text
  }

  /**
   * Joins array of classes while cleaning duplicated spaces
   * @param {array} arr 
   * @returns 
   */
  static classArray = (arr) => {
    let res = (arr instanceof Array) ? arr.join(" ") : arr
    return res.trim().replace(/  +/g, " ")
  }

  /**
   * Returns number formatted by given locale
   * It can also handle currency formats (which adds currency marker)
   * @param {*} number 
   * @param {*} locale 
   * @param {*} currency 
   * @returns string
   */
  static getLocaleNumber = (number, locale, currency = null) => {
    let options = {}
    if (currency) {
      options.style = 'currency'
      options.currency = currency
    }
    return new Intl.NumberFormat(locale, options).format(number)
  }

  /**
   * Reads value from localStorage (or returns default value)
   * @param {string} item 
   * @param {any} def 
   * @returns any
   */
  static getLSItem = (item, def = null) => {
    return this.hasLocalStorage() ? localStorage.getItem(item) : def
  }

  /**
   * Writes value into localStorage
   * @param {string} item 
   * @param {any} value 
   * @returns
   */
  static setLSItem = (item, value) => {
    return this.hasLocalStorage() ? localStorage.setItem(item, value) : null
  }

  /**
   * Formats date for data blocks sent to server
   * Works without dayjs into format YYYY-MM-DD_HH_nn_ss
   * Copy of this functions is also used on server for date format consistency
   * @param {*} date 
   * @param {*} sep 
   * @returns 
   */
  static formatDateTime = (date = null, sep = "_") => {
    const d = date || new Date()
    const year = (d.getFullYear() + "").padStart(4, "0")
    const month = (d.getMonth() + "").padStart(2, "0")
    const day = (d.getDate() + "").padStart(2, "0")
    const hour = (d.getHours() + "").padStart(2, "0")
    const minute = (d.getMinutes() + "").padStart(2, "0")
    const second = (d.getSeconds() + "").padStart(2, "0")
    return `${year}-${month}-${day}${sep}${hour}-${minute}-${second}`
  }

  /**
   * Prepares fixes set of parameters used in translation functions (for variable replacements)
   * @param {string} event 
   * @param {string} lang 
   * @param {json} config 
   * @param {json} current 
   * @returns json
   */
  static prepDefaultParamsForTexts = (event, lang, config, current) => {
    const currentEvent = current.events[event]

    const displayVenue = Util.getObjectOrArrayItem(currentEvent.venue)
    const venueNameAndAddress =
      displayVenue
        ? Util.joinArray([
          Util.tx(displayVenue?.name, lang),
          `${displayVenue?.street}, ${displayVenue?.postalCode} ${displayVenue?.city}`
        ], " - ")
        : ""
    const mapUrl = displayVenue ? displayVenue.mapUrl : ""

    const defaultParams = {
      lang: lang,
      event: event,
      currentYear: current.currentYear,
      eventDate: dayjs(currentEvent.startDateTime).format("L"),
      eventTime: dayjs(currentEvent.startDateTime).format("LT"),
      venueNameAndAddress: venueNameAndAddress,
      mapUrl: mapUrl,
      ticketsUrl: (currentEvent.externalLinks || {}).ticketsUrl,
      sessionsUrl: (currentEvent.externalLinks || {}).sessions,
      lectureRegistrationEnd: dayjs(current.dateLimits.lectureRegistration.to).format("L")
    }

    return defaultParams
  }

  /**
   * Generates random number between min & max values
   * @param {integer} min 
   * @param {integer} max 
   * @returns integer
   */
  static getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  /**
   * Shuffles array (random order) - NOT USED
   * @param {array} arr 
   * @returns 
   */
  static shuffleArray = (arr) => {
    for (var c = arr.length - 1; c > 0; c--) {
      var b = Math.floor(Math.random() * (c + 1));
      var a = arr[c];
      arr[c] = arr[b];
      arr[b] = a;
    }
    return arr
  }

  /**
   * Extract host part of the URL string
   * Works only in browser - intentionally does not have try/catch block
   * @param {string} urlString 
   * @returns string
   */
  static extractHost = (urlString) => {
    var url = new URL(urlString)
    return url.hostname
  }

  /**
   * Removes double slashes from string
   * Mostly used for URL cleanup when concatenation is done
   * @param {string} str 
   * @returns string
   */
  static replaceDoubleSlashes = (str) => {
    return str.replace(/\/\//g, "/")
  }

  /**
   * Removes leading slash - used to avoid double slashes
   * @param {string} str 
   * @returns string
   */
  static removeLeadingSlash = (str) => {
    return str.indexOf("/") === 0 ? str.substring(1) : str
  }

  /**
   * Empties the string if it contains only the slash
   * @param {string} str 
   * @returns string
   */
  static removeSlashOnly = (str) => {
    return str === "/" ? "" : str
  }

  /**
   * Get first available identifier - this is used to get general translation, and if not found, local (by given prefixes)
   * @param {object} i18n 
   * @param {string} ident 
   * @param {array} prefixes (groups)
   * @returns string
   *
   * Usage:
   * Util.getTransIdent(i18n, ident, ["pages.exhibitors", "general"])
   */
  static getTransIdent = (i18n, ident, prefixes = ["general"]) => {
    for (let i = 0; i < prefixes.length; i++) {
      const key = `${prefixes[i]}.${ident}`
      if (i18n.exists(key)) {
        return key
      }
    }
    return null
  }

  /**
   * Replaces local characters with ASCII ones to keep slugs properly formatted
   * @param {string} str 
   * @param {integer} maxLength 
   * @param {string} suffix 
   * @returns string
   */
  static slugify = (str, maxLength = null, suffix = null) => {
    str = str.replace(/^\s+|\s+$/g, '') // trim

    if (maxLength) {
      if (str.length > maxLength) {
        str = str.substring(0, maxLength)
      }
    }

    if (suffix) {
      str = str + "-" + suffix
    }

    str = str.toLowerCase()

    // replace special chars - but important to mark
    str = str.replace(/\+/g, "-plus")

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñçšđčćž·/_,:;"
    var to = "aaaaeeeeiiiioooouuuuncsdccz------"
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  }

  /**
   * Gets correct text, by given language, from json value
   * If value is string, then that is returned directly
   * @param {json} value 
   * @param {string} lang 
   * @returns string
   */
  static getText = (value, lang, options = {}) => {
    if (!value) return null

    let result = value

    if (typeof value === 'object') {
      result = value.hasOwnProperty(lang) ? value[lang] : null
    }

    if (options.newLineToBreak) {
      result = result.replace(/\r\n/g, '<br/>')
    }

    return result
  }

}

export default Util