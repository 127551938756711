import React, { useState, useMemo } from 'react'
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Util from './util'

const PageContext = React.createContext({
})

export const PageContextProvider = ({ pageContext, children }) => {
  const { i18n } = useTranslation()

  if (i18n.language !== pageContext.lang) {
    i18n.changeLanguage(pageContext.lang) // needs to be behind condition to stop massive re-renders
  }

  dayjs.locale(pageContext.lang) // needs to be outside the language condition because it needs to load every time

  const [isLight, setIsLight] = useState(Util.isLightMode())
  const setCtxIsLight = (value) => {
    setIsLight(value)
  }

  const ctxValue = useMemo(() => ({
    ...pageContext,
    isLight,
    setCtxIsLight
  }), [isLight, pageContext])

  return (
    <PageContext.Provider
      value={ctxValue}
    >
      {children}
    </PageContext.Provider>
  )
}

PageContextProvider.propTypes = {
  pageContext: PropTypes.object,
  children: PropTypes.any
}

export const usePageContext = () => React.useContext(PageContext)